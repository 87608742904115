<template>
    <div class="banner"
        :style="cssProps"
    >
        <h1>{{heading}}</h1>
        <h1>{{subHeading}}</h1>
    </div>
</template>

<script>

export default {
    name:'Banner',
    props:{
        heading:{
            type: String,
        },
        subHeading:{
            type: String,
        },
        img:{
            type: String,
        }
    },
    data() {
        return {
            cssProps: {
                background:`linear-gradient(0deg, rgba(34, 33, 34, 0.3), rgba(34, 33, 34, 0.3)), url(${require(`@/assets/images/${this.img}`)})`,
                backgroundPosition: `50% 70%`,
                backgroundRepeat: `no-repeat`,
                backgroundSize: `cover`,
            }
        }
    }
}
</script>

<style lang="scss">
@import './Banner.scss';
</style>