<template>
    <div>
        <div class="designer-detailspage">
            <BreadCrumbs :current="designerDetails.designer_data.name" />
            <Banner img="indoors.jpg" />
            <!-- <div class="head-display">
                <div class="display-image">
                    <div class="img">
                    </div>
                    <div class="display-info">
                        Get Your Home Designed by Very Best Interior Designers Handpicked by Us
                    </div>
                </div>
            </div> -->
            <template v-if="windowWidth > 1100">
                <DesignerDetailContainerDesktop
                    v-if="designerDetails.length != 0"
                    :data="designerDetails"
                    :activeComponent="activeComponent"
                    :groupId="groupId"
                    @switchComponent="switchComponent"
                />
            </template>
            <template v-else>
                <DesignerDetailContainerMobile
                    v-if="designerDetails.length != 0"
                    :data="designerDetails"
                    :activeComponent="activeComponent"
                    :groupId="groupId"
                    @switchComponent="switchComponent"
                />
            </template>
        </div>
        <div v-if="groupCarsouelShow" class="carousel-modal">
            <div v-if="windowWidth < 1100">
                <div
                    class="carousel-container-mobile"
                    @click="closeGroupCarsouelModal(false)"
                >
                    <div class="carousel-img-wrapper">
                        <div class="carousel-header">
                            <h1 class="carousel-heading">
                                {{ designerDetailGroup.group_name }}
                            </h1>

                            <div
                                class="groupmodel-cross"
                                @click="closeGroupCarsouelModal(false)"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="25"
                                    height="24"
                                    class="close-button"
                                    fill="none"
                                    viewBox="0 0 25 24"
                                >
                                    <path
                                        fill="#fff"
                                        d="M19.275 6.41L17.865 5l-5.59 5.59L6.685 5l-1.41 1.41 5.59 5.59-5.59 5.59L6.685 19l5.59-5.59 5.59 5.59 1.41-1.41-5.59-5.59 5.59-5.59z"
                                    />
                                </svg>
                            </div>
                        </div>

                        <img
                            :src="
                                designerDetailGroup.images_data[
                                    currentCarsouelIndex
                                ].image_thumb
                            "
                            @click.stop="this.toggleGroupCarsouel(true)"
                        />
                    </div>
                    <div class="mobile-arrow">
                        <span class="left" @click.stop="next(-1)">
                            <svg
                                width="25"
                                height="24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                style="transform: scale(2);"
                            >
                                <path
                                    d="M15.675 7.41 14.267 6l-5.992 6 5.992 6 1.408-1.41L11.1 12l4.574-4.59z"
                                    fill="#fff"
                                />
                            </svg>
                        </span>
                        <span class="right" @click.stop="next(+1)">
                            <svg
                                width="25"
                                height="24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                style="transform: scale(2);"
                            >
                                <path
                                    d="M10.283 6 8.875 7.41 13.449 12l-4.574 4.59L10.283 18l5.992-6-5.992-6z"
                                    fill="#fff"
                                />
                            </svg>
                        </span>
                    </div>
                </div>
            </div>
            <div v-else>
                <div class="carsouel-header">
                    <h1 class="carousel-heading">
                        {{ designerDetailGroup.group_name }}
                    </h1>
                    <span
                        class="groupmodel-cross"
                        @click="closeGroupCarsouelModal(false)"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="25"
                            height="24"
                            class="close-button"
                            fill="none"
                            viewBox="0 0 25 24"
                        >
                            <path
                                fill="#fff"
                                d="M19.275 6.41L17.865 5l-5.59 5.59L6.685 5l-1.41 1.41 5.59 5.59-5.59 5.59L6.685 19l5.59-5.59 5.59 5.59 1.41-1.41-5.59-5.59 5.59-5.59z"
                            />
                        </svg>
                    </span>
                </div>
                <div
                    class="carousel-container"
                    @click="closeGroupCarsouelModal(false)"
                >
                    <span class="left" @click.stop="next(-1)">
                        <svg
                            width="25"
                            height="24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            style="transform: scale(2);"
                        >
                            <path
                                d="M15.675 7.41 14.267 6l-5.992 6 5.992 6 1.408-1.41L11.1 12l4.574-4.59z"
                                fill="#fff"
                            />
                        </svg>
                    </span>
                    <div class="carousel-img-wrapper">
                        <img
                            :src="
                                designerDetailGroup.images_data[
                                    currentCarsouelIndex
                                ].carousel
                            "
                            @click.stop="this.toggleGroupCarsouel(true)"
                        />
                    </div>
                    <span class="right" @click.stop="next(+1)">
                        <svg
                            width="25"
                            height="24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            style="transform: scale(2);"
                        >
                            <path
                                d="M10.283 6 8.875 7.41 13.449 12l-4.574 4.59L10.283 18l5.992-6-5.992-6z"
                                fill="#fff"
                            />
                        </svg>
                    </span>
                </div>
            </div>
        </div>
        <Footer/>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import RegisterStoreModule from 'mixins/RegisterStoreModule'
import DesignerDetailsPageModule from 'store/modules/DesignerDetailsPage'

import Banner from 'components/Banner'
import DesignerDetailContainerDesktop from 'components/DesginerDetailContainer/Desktop'
import DesignerDetailContainerMobile from 'components/DesginerDetailContainer/Mobile'
import BreadCrumbs from 'componentsv2/BreadCrumbs'
import Footer from 'componentsv2/Footer'

export default {
    name: 'DesignerDetailsPage',
    components: {
        Banner,
        DesignerDetailContainerDesktop,
        DesignerDetailContainerMobile,
        BreadCrumbs,
        Footer,
    },
    mixins: [RegisterStoreModule],
    data() {
        return {
            activeComponent: '',
            groupId: null,
        }
    },
    created() {
        this.registerStoreModule(
            'DesignerDetailsPageModule',
            DesignerDetailsPageModule,
        )
        this.fetchDesignerDetails({ designer_id: this.$route.params.id }).then(
            response => {
                if (response.status == 'Success') {
                    document.querySelector(
                        "meta[property='og:title']",
                    ).content = `${this.designerDetails.designer_data.name}`

                    document.querySelector(
                        "meta[property='og:description']",
                    ).content = `${this.designerDetails.designer_data.description}`
                }
            },
        )
    },
    mounted() {
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize)
        })
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onResize)
    },
    computed: {
        ...mapState({
            designerDetails: state =>
                state.DesignerDetailsPageModule.designerDetails,
            windowWidth: state => state.AppModule.windowWidth,
            groupCarsouelShow: state =>
                state.DesignerDetailsPageModule.groupCarsouelShow,
            currentCarsouelIndex: state =>
                state.DesignerDetailsPageModule.currentCarsouelIndex,
            designerDetailGroup: state =>
                state.DesignerDetailsPageModule.designerDetailGroup,
        }),
    },
    destroyed() {
        // this.$store.unregisterModule('DesignerDetailsPageModule')
    },
    methods: {
        ...mapActions({
            fetchDesignerDetails: 'fetchDesignerDetails',
            toggleGroupCarsouel: 'toggleGroupCarsouel',
            updateCarouselIndex: 'updateCarouselIndex',
            onResize: 'onResize',
        }),

        closeGroupCarsouelModal(toggle) {
            this.toggleGroupCarsouel(toggle)
            document.querySelector('body').style.overflow = 'auto'
        },

        next(index) {
            if (index == -1) {
                if (this.currentCarsouelIndex == 0) {
                    this.updateCarouselIndex(
                        this.designerDetailGroup.images_data.length - 1,
                    )
                } else {
                    this.updateCarouselIndex(this.currentCarsouelIndex - 1)
                }
            } else {
                if (
                    this.currentCarsouelIndex ==
                    this.designerDetailGroup.images_data.length - 1
                ) {
                    this.updateCarouselIndex(0)
                } else {
                    this.updateCarouselIndex(this.currentCarsouelIndex + 1)
                }
            }
        },
        switchComponent(obj) {
            this.activeComponent = obj.componentName
            this.groupId = obj.id
        },
    },
}
</script>

<style lang="scss">
@import './DesignerDetailsPage.scss';
</style>
