<template>
    <div v-show='isActive' class="tab__content">
        <slot></slot>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: 'Tab'
        }
    },
    data () {
        return {
            isActive: true
        }
    },
    mounted() {
      this.isActive = this.selected;
    }
}
</script>
<style lang="scss">
@import "./Tab.scss";
</style>