<template>
    <div class="designer-detail-container">   
        <div class="designer-detail-row">
            <div class="info-col">
                <DesignerDetailInfoCard :data="data.designer_data"/>
            </div>
            <div class="group-col">
                <!-- <router-view  :data="data.groups_data"/> -->
                <component
                    v-bind:is="activeComponent!=''?activeComponent:'DesignerDetailPortfolio'"
                    :portfolio="data.groups_data"
                    :groupId="groupId"
                    @changeComponent="changeComponent"
                    source="D"
                >
                </component>
            </div>
        </div> 
    </div>
</template>

<script>
import DesignerDetailInfoCard from 'components/DesignerDetailInfoCard'
import DesignerDetailPortfolio from 'components/DesignerDetailPortfolio'
import DesignerDetailGroup from 'components/DesignerDetailGroup/Desktop'

export default {
    name:'DesignerDetailContainer',
    components:{
        DesignerDetailInfoCard,
        DesignerDetailPortfolio,
        DesignerDetailGroup
    },
    props:{
        data:{
            type: Object
        },
        activeComponent:{
            type: String
        },
        groupId:{
            type: Number
        }
    },
    data(){
        return{
            // activeComponent: "DesignerDetailPortfolio",
            // groupId: null,
        }
    },
    methods:{
        changeComponent(obj){
            // this.activeComponent = obj.componentName
            // this.groupId = obj.id
            this.$emit('switchComponent',obj)
        }
    }
    
}
</script>

<style lang="scss">
@import "./DesignerDetailContainer.scss";
</style>