import Api from 'utils/Api'

export function getDesignersList(){
    return Api
        .get('InteriorDesign/designer/')
        .then(response => {
            return response.data
        })
        .catch(e => {
            return { status: e.response.status, data: e.response.data }
        })
}

export function getDesigner({designer_id}){

    return Api
        .get(`InteriorDesign/designer/${designer_id}/`)
        .then(response =>{
            return response.data
        })
        .catch(e => {
                return { status: e.response.status, data: e.response.data }
            })
}


export function getDesignerPortfolioGroup({designer_id}){

    return Api
        .get(`InteriorDesign/portfolio-group/${designer_id}/`)
        .then(response =>{
            return response.data
        })
        .catch(e => {
                return { status: e.response.status, data: e.response.data }
            })
}