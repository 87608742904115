<template>
    <div class="designer-detail-container">
        <div class="designer-profile">
            <div class="designer-profile-img">
                <img
                    :src="
                        data.designer_data.image
                            ? data.designer_data.image
                            : require('assets/images/default-designer.png')
                    "
                />
            </div>
            <h1>{{ data.designer_data.name }}</h1>
            <p class="designer-projects">
                Projects: <span>{{ data.designer_data.num_of_projects }}</span>
            </p>
        </div>
        <div class="designer-profilecontainer">
            <!-- <DesignerDetailTabMobile :designer="data.designer_data" :groups_data="data.groups_data"/> -->
            <component
                v-bind:is="
                    activeComponent != ''
                        ? activeComponent
                        : 'DesignerDetailTabMobile'
                "
                :designer="data.designer_data"
                :groups_data="data.groups_data"
                :groupId="groupId"
                @changeComponent="changeComponent"
                source="M"
            >
            </component>
        </div>
    </div>
</template>

<script>
import DesignerDetailTabMobile from 'components/DesignerDetailTabMobile'
import DesignerDetailGroup from 'components/DesignerDetailGroup/Desktop'

export default {
    name: 'DesignerDetailContainer',
    components: {
        DesignerDetailTabMobile,
        DesignerDetailGroup,
    },
    props: {
        data: {
            type: Object,
        },
        activeComponent: {
            type: String,
        },
        groupId: {
            type: Number,
        },
    },
    data() {
        return {
            // activeComponent: "DesignerDetailTabMobile",
            // groupId: null
        }
    },
    methods: {
        changeComponent(obj) {
            // this.activeComponent = obj.componentName
            // this.groupId = obj.id
            this.$emit('switchComponent', obj)
        },
    },
}
</script>

<style lang="scss">
@import './DesignerDetailContainer.scss';
</style>
