<template>
    <div class="designerdetail-portfoliogroup">
        <div class="header">
            <!-- <router-link :to="{name: 'DesignerDetailPortfolio'}"><button class="groupbutton">Back</button></router-link> -->
            <Button name="Back" normal :handleClick="goToPortfolio" />
            <h1 class="groupname">{{ designerDetailGroup.group_name }}</h1>
        </div>
        <div class="portfoliogroup-container">
            <div
                v-for="(portfolio, index) in designerDetailGroup.images_data"
                :key="`portfolio_` + portfolio.id"
                class="portfoliogroup-col"
            >
                <PCard
                    :name="portfolio.name"
                    :img="portfolio.image_thumb"
                    :id="portfolio.id"
                    @click.native="showGroupCarsouel(true, index)"
                    style="cursor: pointer"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import PCard from 'components/PCard'
import Button from 'componentsv2/Button'

export default {
    name: 'DesignerDetailGroup',
    components: {
        PCard,
        Button,
    },
    props: {
        groupId: {
            type: Number,
        },
        source: {
            type: String,
        },
    },
    data() {
        return {}
    },
    created() {
        this.fetchDesignerDetailGroup({ designer_id: this.groupId })
    },
    mounted() {},
    computed: {
        ...mapState({
            designerDetailGroup: state =>
                state.DesignerDetailsPageModule.designerDetailGroup,
        }),
    },
    methods: {
        ...mapActions({
            fetchDesignerDetailGroup: 'fetchDesignerDetailGroup',
            toggleGroupCarsouel: 'toggleGroupCarsouel',
            updateCarouselIndex: 'updateCarouselIndex',
        }),

        showGroupCarsouel(toggle, index) {
            this.toggleGroupCarsouel(toggle)
            this.updateCarouselIndex(index)
            document.querySelector('body').style.overflow = 'hidden'
        },

        goToPortfolio() {
            // switch(this.source){
            //     case 'D':
            //         this.$emit('changeComponent',{
            //             componentName: "DesignerDetailPortfolio",
            //             id: null
            //         })
            //         break
            //     case 'M':
            //         this.$emit('changeComponent',{
            //             componentName: "DesignerDetailTabMobile",
            //             id: null
            //         })
            //         break
            // }
            this.$emit('changeComponent', {
                componentName: '',
                id: null,
            })
        },
    },
}
</script>

<style lang="scss">
@import './DesignerDetailGroup.scss';
</style>
