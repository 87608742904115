import { getDesigner, getDesignerPortfolioGroup } from 'api/designerApi'

const DesignerDetailsPage = {
    state: ()=>({
        designerDetails:[],
        designerDetailGroup:[],
        groupCarsouelShow: false,
        currentCarsouelIndex: 0
    }),

    mutations:{
        setDesignerDetails(state, payload){
            state.designerDetails = payload
        },
        setDesignerDetailGroup(state, payload){
            state.designerDetailGroup = payload
        },
        setGroupCarsouelShow(state, payload){
            state.groupCarsouelShow = payload
        },
        setCurrentCarsouelIndex(state,payload){
            state.currentCarsouelIndex=payload
        }
        
    },

    getters:{

    },

    actions:{
        fetchDesignerDetails({commit} , obj){
            const designer_param = obj.designer_id;
            return getDesigner({
                'designer_id':designer_param,
            }).then(payload => {
                    if (payload.status === 404) {
                        console.log('fetching failed')
                    } else {
                        commit('setDesignerDetails', payload)
                        return payload
                    }
                })
        
        },

        fetchDesignerDetailGroup({commit} , obj){
            const designer_param = obj.designer_id;
            return getDesignerPortfolioGroup({
                'designer_id':designer_param,
            }).then(payload => {
                if (payload.status === 404) {
                    console.log('fetching failed')
                } else {
                    commit('setDesignerDetailGroup', payload)
                    return payload
                }
            })
        },

        toggleGroupCarsouel({commit}, toggle){
            commit('setGroupCarsouelShow',toggle)
            if(toggle){
                document.querySelector("body").classList.add("overflow-hidden");
            }else{
                document.querySelector("body").classList.remove("overflow-hidden");
            }
        },
        updateCarouselIndex({commit},index){
            commit('setCurrentCarsouelIndex',index)
        }
        
    }
}

export default DesignerDetailsPage