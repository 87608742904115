<template>
    <div class="designerdetail-tabcontainer">   
        <Tabs class="designerdetail-tabwrapper">
            <Tab class="designerdetail-tab" title="Info">
                <div class="mdesignerdetail-locationcontainer">
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="20" fill="none" viewBox="0 0 14 20">
                        <path fill="#000" d="M12.796 3.426c-.582-1.021-1.42-1.873-2.43-2.473-1.01-.6-2.159-.928-3.334-.951h-.308c-1.175.022-2.325.35-3.336.95-1.01.6-1.849 1.452-2.431 2.473-.61 1.04-.939 2.222-.955 3.427-.016 1.206.282 2.396.864 3.452l4.959 9.077.007.012c.105.184.258.337.441.443.184.107.392.163.604.163.213 0 .421-.056.605-.163.183-.106.335-.259.441-.443l.007-.012 4.957-9.079c.582-1.056.88-2.246.864-3.452-.016-1.205-.345-2.387-.955-3.427v.003zM6.875 9.063c-.556 0-1.1-.165-1.562-.474-.463-.309-.823-.748-1.036-1.262-.213-.514-.268-1.08-.16-1.625.109-.545.376-1.046.77-1.44.393-.393.894-.66 1.44-.769.545-.109 1.11-.053 1.624.16s.953.573 1.262 1.036c.31.462.474 1.006.474 1.562 0 .745-.297 1.46-.825 1.987-.527.528-1.241.824-1.987.825z"/>
                    </svg>
                    <p class="designerdetail-location">{{designer.location}}</p>
                </div>
                <SeeMore style="margin-top: 20px;" heading="About" :subHeading="designer.description"/>
                <SeeMore style="margin-top: 20px;" heading="Specialities" :subHeading="designer.specialities"/>
                <SeeMore style="margin-top: 20px;" heading="Services Provided" :subHeading="designer.services_provided"/>
                <h1 class="tab-heading">Cost</h1>
                <p class="tab-subheading">Per Sqft Rate</p>
                <p class="tab-subheading">Budget Range</p>
            </Tab>
            <Tab class="designerdetail-tab" title="Portfolio">
                <div class="designerdetail-tabrow">
                    
                    <div
                        v-for="portfolio in groups_data"
                        :key="`mportfolio_`+portfolio.id"
                        class="designerdetail-tabcol"
                    >                    
                        <!-- <router-link :to="{name: 'DesignerDetailGroupMobile', params: { group_id: portfolio.id }}"> -->
                            <PCard :name="portfolio.group_name" :img="portfolio.group_image_thumb" :id="portfolio.id" @click.native="goToGroup(portfolio.id)"/>
                        <!-- </router-link> -->
                    </div>
                </div>
            </Tab>
        </Tabs>
    </div>
</template>

<script>
import SeeMore from 'components/SeeMore'
import Tabs from 'components/Tabs'
import Tab from 'components/Tab'
import PCard from 'components/PCard'

export default {
    name:'DesignerDetailTabMobile',
    components:{
        SeeMore,
        Tabs,
        Tab,
        PCard
    },
    props:['designer','groups_data'],
    data(){
        return{
        }
    },
    methods:{
        goToGroup(id){
            this.$emit('changeComponent',{
                componentName: "DesignerDetailGroup",
                id: id
            })
        }
    }
}
</script>

<style lang="scss">
@import "./DesignerDetailTabMobile.scss";
</style>